import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import mp3_file from '../sound/far_01.mp3';

const TopWrapper = styled.div`
display: block;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
background-color: #10111A;
height: 100vh;
`;

const LogoWrapper = styled.div`
height: 85vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
background-color: #10111A;
`;

const Content = styled.div`
display: block;
flex-direction: column;
justify-content: center;
align-items: center;
`;

const ChoobieFace = styled.img`

display: ${({isHidden}) => isHidden ? 'block' : 'none'};
margin-left: auto;
margin-right: auto;
width: 25%;

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
`;

const FunkyButton = styled.button`
display: ${({isHidden}) => isHidden ? 'none' : 'block'};
`;

function Test() {

  const [spinnymode, setToggle] = useState(false);
  const [audio] = useState(new Audio(mp3_file));
  const history = useHistory();

  const handleToggle = () => {
    setToggle(!spinnymode)
    audio.play();
}

  audio.onended = function() {
    history.push('/choobietalker')
  }
  
    return (
        <>
        <TopWrapper>
          <Content>
            <LogoWrapper>
              <FunkyButton onClick={handleToggle} isHidden={spinnymode}>ACTIVATE FUNKY MODE</FunkyButton>
              <ChoobieFace style={{animation: `spin 0.5s linear infinite`}} src='/images/choobie.jpg' alt="img" isHidden={spinnymode} />
            </LogoWrapper>
          </Content>
        </TopWrapper>
        </>
      );
}

export default Test;