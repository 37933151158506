import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import Cards from '../components/Cards';
import Faq from '../components/Faqs/FaqList';
import { Link } from 'react-router-dom';

const TopWrapper = styled.div`
display: block;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
background-color: #10111A;
`;

const LogoWrapper = styled.div`
height: 35vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
background-color: #10111A;
`;

const Section = styled.section`
background-color: #10111A;
display: block;
background-repeat: no-repeat;
background-size: contain;
height: 100vh;
`;

const Content = styled.div`
width: 100%;
height: 100%;
overflow: auto;
`;

const Desc = styled.p`
font-size: 24px;
color: #9ea0ac;
line-height: 30px;
text-align: center;
justify-content: center;
align-items: center;
margin-bottom: 2rem;
`;

const Status = styled.p`
text-align: center;
margin-bottom: 1rem;
color: #9ea0ac;
`;

const FAQSection = styled.section`
padding: 60px 0;
padding-left: 150px;
padding-right: 150px;
`;

const FAQTitle = styled.p`
text-align: center;
font-size: 25px;
color: #fff;
font-weight: 400;
margin-bottom: 24px;
`;

const Notice = styled.div`
margin-bottom: 100px;
`;

const NoticeText = styled.h5`
color: rgb(35, 45, 86);
text-align: center;
`;

const Divider = styled.div`
position: relative;
margin-top: 25px;
height: 1px;

&:before {
    content: "";
	position: absolute;
	top: 0;
	left: 5%;
	right: 5%;
	width: 90%;
	height: 1px;
	background-image: linear-gradient(to right, transparent, rgb(86, 112, 249), transparent);
}
`;

const Logo = styled.img`
top: 0;
display: block;
border-radius: 10px;
left: 0;
height: 50%;
-webkit-transition: -webkit-transform 0.5s ease;
-moz-transition: -moz-transform 0.5s ease;
transition: transform 0.5s ease;
margin-top: 1rem;
margin-bottom: 1rem;


&:hover {
  transform: scale(1.1);
}

@media only screen and (min-width: 1200px) {
    margin-bottom: 1rem;
    margin-top: 2rem;
}
`;

const DemoWrapper = styled.div`
height: 35vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
background-color: #10111A;
`;

const Demo = styled.img`
top: 0;
display: flex;
left: 0;
height: 200%;
margin-top: 1rem;
margin-bottom: 1rem;
`;

const SignupButton = styled(Link)`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 12px;
margin-bottom: 12px;
text-decoration: none;
`;

const ButtonStyle = styled.button`
background-color: rgb(86, 112, 249);
color: #fff;
padding: 15px 27px;
border: 1px solid var(--primary);
transition: all 0.3 ease-out;
`;

function ChoobieTalker() {
    return (
        <>
        <Section>
          <Helmet>
            <title>Wheelz - ChoobieTalker</title>
            <meta name="description" content="ChoobieTalker Home Page" />
          </Helmet>
          <Content>
            <TopWrapper>
              <LogoWrapper>
                <Logo alt='Missing Image' src='/images/choobietalker-logo.png' />
              </LogoWrapper>
              <Status>(Early Access)</Status>
              <SignupButton to="/redirect/choobietalker-signup">
                <ButtonStyle>
                  SIGN UP
                </ButtonStyle>
              </SignupButton>
              <Desc>ChoobieTalker is a customizable speech and subtitle solution for mute streamers and virtual youtubers currently in development.<br />Early access will be available soon on Windows for a limited amount of streamers who can fill out a small form.</Desc>
              <DemoWrapper>
                <Demo alt='Missing Image' src='/images/choobietalker-demo-1.png' />
              </DemoWrapper>
            </TopWrapper>
            <FAQSection>
              <FAQTitle>Frequently Asked Questions</FAQTitle>
              <Faq />
            </FAQSection>
            <Divider />
            <Cards />
            <Notice>
              <NoticeText>This page is incomplete and is subject to change</NoticeText>
            </Notice>
          </Content>
        </Section>
        </>
      );
}

export default ChoobieTalker;