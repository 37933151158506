import React from 'react';
import GlobalStyle from './globalStyles';
import Navbar from './components/Navbar';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import PageNotFound from './pages/NotFoundPage';
import Services from './pages/Services';
import ChoobieTalker from './pages/ChoobieTalker';
import ChoobieSpinTest from './pages/Test';

function App() {
  return (
    <>
      <GlobalStyle />
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/home' exact component={Home} />
        <Route path='/services' component={Services} />
        <Route path='/contact' component={Home} />
        <Route path='/choobietalker' component={ChoobieTalker} />
        <Route path='/redirect/choobie' component={() => { window.location.href = 'https://www.youtube.com/c/ChoobiePatootie'; return null;}}/>
        <Route path='/redirect/kromia' component={() => { window.location.href = 'https://www.twitch.tv/kromia'; return null;}}/>
        <Route path='/redirect/choobietalker-signup' component={() => { window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeJexOadEYKUEKg0RG1kHyTysc-EbWtVvLewuacMEQ2FtLUgg/viewform?usp=sf_link'; return null;}}/>
        <Route path='/choobie-funky-dance' component={ChoobieSpinTest} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </>
  );
}

export default App;