import React from 'react';
import styled from 'styled-components/macro';
import { Button } from './Button.js';

const HeroContainer = styled.div`
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
background-color: #10111A;
`;

const H1 = styled.h1`
color: #fff;
font-size: 100px;
margin-bottom: 8px;
`;

const P = styled.p`
margin-top: 7px;
color: #fff;
font-size: 32px;
font-size: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
`;

function HeroSection() {
    return (
        <HeroContainer>
            <H1>WIP</H1>
            <P>Will be finished soon!</P>
        </HeroContainer>
    );
}

export default HeroSection;