import React from 'react';
import styled from 'styled-components/macro';
import CardItem from './CardItem';

const bigScreen = "only screen and (min-width: 1200px)";

const CardTheme = styled.div`
padding: 4rem;
background-color: #10111A;
`;

const CardTitle = styled.h1`
text-align: center;
color: #fff;
`;

const CardContainer = styled.div`
display: flex;
flex-flow: column;
align-items: center;
max-width: 1120px;
width: 90%;
margin: 0 auto;
`;

const CardWrapper = styled.div`
position: relative;
margin: 50px 0 45px;
`;

const CardItems = styled.ul`
margin-bottom: 24px;

@media ${bigScreen} {
    display: flex;
}
`;

function Cards() {
    return (
        <CardTheme>
            <CardTitle>Current Users</CardTitle>
            <CardContainer>
                <CardWrapper>
                    <CardItems>
                        <CardItem src='/images/choobie.jpg' text='Choobie' path='/redirect/choobie' />
                        <CardItem src='/images/kromia.jpg' text='Kromia' path='/redirect/kromia' />
                    </CardItems>
                </CardWrapper>
            </CardContainer>
        </CardTheme>
    );
}

export default Cards;