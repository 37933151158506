import styled from 'styled-components';

export const StyledFaq = styled.div`
cursor: pointer;
margin: 0 0 10px;


div.faq-question {
    font-size: 15px;
    font-weight: 800;
    padding: 1rem;
    border: 1px solid var(--primary);
    border-color: #9ea0ac;
    margin: 0 0 5px;
    color: #fff;
}

div.faq-answer {
    background: rgb(108, 111, 124);
    overflow: hidden;

    span {
        display: block;
        padding: 20px 10px;
    }
}
`;

export const StyledFaqList = styled.div`
background: rgb(48, 49, 72);
padding: 1rem;
`;