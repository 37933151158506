import React, { useState } from 'react';
import { StyledFaq } from './faq-styles'

const Faq = props => {
    const { question, answer } = props;
    const [isOpen, toggleOpen] = useState(false);

    return (
        <StyledFaq onClick={() => toggleOpen(!isOpen)}>
            <div className='faq-question'>
                <span> {question}</span>
            </div>

            <div className='faq-answer' style={isOpen ? { display: 'block' } : { display: 'none' }}>
                <span>{answer}</span>
            </div>
        </StyledFaq>
    );
};

export default Faq;