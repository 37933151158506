import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const smallScreen = "screen and (max-width: 960px)";

const NavbarContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 80px;
max-width: 1500px;
`;

const Nav = styled.nav`
background-color: #1B1C2A;
height: 80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 1.2rem;
position: sticky;
top: 0;
z-index: 999;
`;

const NavItem = styled.li`
height: 80px;

@media ${smallScreen} {
    position: relative;
}
`;

const NavLink = styled(Link)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.5rem 1rem;
height: 100%;

&:hover {
    border-bottom: 4px solid rgb(86, 112, 249);
    transition: all 0.2s ease-out;
}

@media ${smallScreen} {
    text-align: center;
    width: 100%;
    display: table;
    transition: all 0.3s ease-out;

    &:hover {
        background-color: rgb(86, 112, 249);
        color: rgb(35, 45, 86);
        border-radius: 0;
        transition: 250ms;
    }
}
`;

const NavMenu = styled.ul`
display: grid;
grid-template-columns: repeat(4, auto);
grid-gap: 10px;
list-style: none;
text-align: center;
width: 60vw;
justify-content: end;
margin-right: 2rem;

@media ${smallScreen} {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;

    ${({ click }) => click && `
    background: #10111A;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    `}
}
`;

const MenuIcon = styled.div`
display: none;

@media ${smallScreen} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
}
`;

const Logo = styled(Link)`
color: #fff;
justify-self: start;
margin-left: 20px;
cursor: pointer;
text-decoration: none;
font-size: 2rem;
display: flex;
align-items: center;

  @media ${smallScreen} {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }
`;

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => { 
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
            <Nav>
                <NavbarContainer>
                    <Logo to='/' onClick={closeMobileMenu}>
                        WHEELZ 
                    </Logo>
                    <MenuIcon onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </MenuIcon>
                    <NavMenu click={click}>
                        <NavItem>
                            <NavLink to='/' onClick={closeMobileMenu}>
                                HOME
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='/choobietalker' onClick={closeMobileMenu}>
                                CHOOBIETALKER
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='/contact' onClick={closeMobileMenu}>
                                CONTACT
                            </NavLink>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar
