import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const NotFound = styled.div`
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #10111A;
`;

const PageTitle = styled.h1`
color: #fff;
font-size: 150px;
margin-top: -100px;
`;

const PageInfo = styled.p`
margin-top: 8px;
color: #fff;
font-size: 32px;
`;

const HomeButton = styled(Link)`
display: block;
margin-top: 12px;
text-decoration: none;
`;

const ButtonStyle = styled.button`
background-color: transparent;
color: #fff;
padding: 8px 20px;
border: 1px solid var(--primary);
transition: all 0.3 ease-out;
`;

function NotFoundPage() {
    return (
        <>
          <NotFound>
            <Helmet>
              <title>Wheelz - 404</title>
              <meta name="description" content="Page not found" />
            </Helmet>
            <PageTitle>404</PageTitle>
            <PageInfo>Page not found!</PageInfo>
            <HomeButton to="/">
              <ButtonStyle>
                GO HOME
              </ButtonStyle>
            </HomeButton>
          </NotFound>
        </>
      );
}

export default NotFoundPage;