import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const bigScreen = "only screen and (min-width: 1200px)";

const CardsItem = styled.li`
display: flex;
flex: 1;
margin: 0 1rem;
border-radius: 10px;

@media ${bigScreen} {
    margin-bottom: 2rem;
}
`;

const CardsItemLink = styled(Link)`
display: flex;
flex-flow: column;
width: 100%;
box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
border-radius: 10px;
overflow: hidden;
text-decoration: none;
`;

const CardsItemPicWrap = styled.figure`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
  &::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    left: 0px;
    padding: 10px 25px;
    max-width: calc((100%) - 60px);
    font-size: 2rem;
    color: var(--light);
    background-color: var(--blue-dark);
  }
}
`;

const CardsItemImg = styled.img`
top: 0;
left: 0;
display: block;
width: 100%;
max-width: 100%;
height: 100%;
max-height: 100%;
-webkit-transition: -webkit-transform 0.5s ease;
-moz-transition: -moz-transform 0.5s ease;
transition: transform 0.5s ease;

&:hover {
  transform: scale(1.1);
}
`;

const CardsItemInfo = styled.div`
padding: 20px 30px 30px;
`;

const CardsItemText = styled.h5`
color: #252e48;
font-size: 18px;
line-height: 24px;
`;

function CardItem(props) {
    return (
        <>
            <CardsItem>
                <CardsItemLink to={props.path}>
                    <CardsItemPicWrap data-category={props.label}>
                        <CardsItemImg alt='Missing Image' src={props.src} />
                    </CardsItemPicWrap>
                </CardsItemLink>
            </CardsItem>
        </>
    );
}

export default CardItem;