import React from 'react';
import Faq from './Faq';
import { StyledFaqList } from './faq-styles';

const faqData = [
    {
        question: "What is ChoobieTalker exactly?",
        answer: "ChoobieTalker is a program that converts your speech to text then converts the result to speech using a speech synthesizer. It also includes a basic subtitle system that's designed for chromakey (green screen) in software such as OBS and StreamLabs. ChoobieTalker was designed with mute streamers in mind to let them speak through software.",
    },
    {
        question: "Why is ChoobieTalker early access?",
        answer: "The early access is because this costs to maintain, and I can only let in so many at a time with my current funds. Another issue that can occur is leaking which, I would like to try to avoid or at least secure my servers from. Only one person is developing this making it a lot to juggle along with my life outside of this.",
    },
    {
        question: "When will Early Access sign-ups start?",
        answer: "Hopefully soon. I am trying to finish up some helpful updates, and I have to harden some of the security further.",
    },
    {
        question: "When will ChoobieTalker be released publically?",
        answer: "Honestly, I have no idea. This software was formerly just made for one person, and this is starting to expand. The more people use it, the more it costs me. In the future, if I can get backing support and someone to help develop it further, it can probably be made public.",
    },
    {
        question: "What languages are supported?",
        answer: "Currently, only English is supported, with no plans to add more at the moment.",
    },
    {
        question: "What voices are added?",
        answer: "There are no default voices as ChoobieTalker uses the ones you have installed. This includes the default Window's voices, IVONA voices, and really any SAPI 5 voices.",
    },
    {
        question: "Do I need a virtual audio cable?",
        answer: "Yes, a virtual audio cable or similar is required. Anyone should work, for example, the one from VB-Audio. You will be able to set the output in ChoobieTalker to the virtual audio cable easily.",
    },
    {
        question: "Can ChoobieTalker run offline?",
        answer: "Sadly no ChoobieTalker cannot run offline. This is due to the fact ChoobieTalker needs Microsoft Azure's services to run the transcript function.",
    },
    {
        question: "Why is it called ChoobieTalker?",
        answer: "The name was thought of on the spot for when the software was originally created for Choobie.",
    },
];

const FaqList = () => {
    return (
        <StyledFaqList>
            {faqData.map((faq, i) => (
                <Faq key={'faq_' + i} question={faq.question} answer={faq.answer} />
            ))}
        </StyledFaqList>
    );
};

export default FaqList;