import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import Particles from "react-tsparticles";
import particleOptions from '../config/particle-config.json';
import HeroSection from '../components/HeroSection';

const smallScreen = "screen and (max-width: 960px)";

const LandingWrapper = styled.div`
display: block;
margin: 0px auto;
background-color: #10111A;
`;

const ParticleScreen = styled(Particles)`
display: block;

@media ${smallScreen} {
  display: none;
}
`;

function Home() {
    return (
        <>
        <LandingWrapper>
          <Helmet>
            <title>Wheelz - Home</title>
            <meta name="description" content="Landing page" />
          </Helmet>
          <HeroSection />
          <ParticleScreen options={particleOptions} />
        </LandingWrapper>
        </>
      );
}

export default Home;